import React from "react"
import Joi from "joi-browser"
import Layout from "../components/layout"
import { setItem } from "../services/collectionServices"
import SEO from "../components/seo"
import Form from "../components/common/form"
import { toast } from "react-toastify"
import SpinnerLoading from "../components/common/spinnerLoading"
import { transformDataReactToLaravel } from "../utils/functions"
import { getCurrentUser } from "../services/authService"

class Registrazione extends Form {
  state = {
    data: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      visionCareCode: "",
    },
    errors: {},
    isLoggedIn: false,
    loading: false,
  }

  schema = {
    first_name: Joi.string()
      .required()
      .label("Nome"),
    last_name: Joi.string()
      .required()
      .label("Cognome"),
    email: Joi.string()
      .required()
      .email()
      .label("Email"),
    password: Joi.string()
      .required()
      .label("Password"),
    visionCareCode: Joi.string()
      .required()
      .label("Codice VisionCare Italia"),
  }

  async componentDidMount() {
    this.setState({ loading: true })
    const user = await getCurrentUser()
    this.setState({ user })
    if (user.id > 0) {
      window.location = "/area-clienti"
    } else {
      this.setState({ loading: false })
    }
  }

  doSubmit = async e => {
    e.preventDefault()
    const errors = this.validate()
    this.setState({ errors: errors || {} })
    if (errors) {
      toast.error("Sono stati riscontrati degli errori di compilazione")
      return
    }

    this.setState({ loading: true })

    const { data } = this.state
    const dataToSend = transformDataReactToLaravel(data)
    dataToSend.id = 0
    dataToSend.relationships.store = {}
    dataToSend.relationships.store.data = {}
    dataToSend.relationships.store.data.attributes = {}
    dataToSend.relationships.store.data.attributes.code = data.visionCareCode

    const setItemSucceded = await setItem("clients/register", dataToSend)
    if (setItemSucceded) {
      if (dataToSend.id === 0) {
        window.location = "/registrazione-avvenuta"
      } else {
        toast.success("Profilo aggiornato con successo")
        this.setState({ loading: false })
      }
    } else {
      this.setState({ loading: false })
    }
  }

  render() {
    const { loading } = this.state
    return (
      <Layout>
        <SEO
          title="Registrati"
          keywords={[`vision care`]}
          description="Registraione nuovo cliente"
        />

        <div className="container">
          {loading && <SpinnerLoading />}
          {!loading && (
            <div className={"row"}>
              <div className="col-12">
                <h1 className={"page-title"}>Registrati</h1>

                <div className="mb-3">
                  Ti stai registrando come nuovo{" "}
                  <span className="text-orange">
                    <strong>cliente</strong>
                  </span>
                  .
                </div>

                <div className={"row"}>
                  <div className={"col-12 col-md-6"}>
                    <form onSubmit={this.handleSubmit} className="pb-5">
                      <div className="form-group">
                        {this.renderInput(
                          "first_name",
                          "Inserisci il tuo nome",
                          "text",
                          false,
                          "bootstrap",
                          "Nome"
                        )}
                      </div>

                      <div className="form-group">
                        {this.renderInput(
                          "last_name",
                          "Inserisci il tuo cognome",
                          "text",
                          false,
                          "bootstrap",
                          "Cognome"
                        )}
                      </div>

                      <div className="form-group">
                        {this.renderInput(
                          "email",
                          "Inserisci la tua email",
                          "text",
                          false,
                          "bootstrap",
                          "Email"
                        )}
                      </div>

                      <div className="form-group">
                        {this.renderPassword(
                          "password",
                          "Inserisci la tua password",
                          "password",
                          false,
                          "bootstrap",
                          "Password"
                        )}
                      </div>

                      <div className="form-group">
                        {this.renderInput(
                          "visionCareCode",
                          "Inserisci il tuo codice UTENTE Vision Care Italia",
                          "text",
                          false,
                          "bootstrap",
                          "Il tuo codice utente VisionCare Italia"
                        )}
                      </div>

                      <div className="form-group">
                        {this.renderButton("Registrami", true)}
                      </div>

                      <ul>
                        <li className="my-0">
                          <a href="/registrazione-agente">
                            <span className="text-orange">
                              Vuoi registrarti come <strong>agente</strong>?
                            </span>
                          </a>
                        </li>
                        <li className="my-0">
                          <a href="/login">
                            <span className="text-orange">Torna al login</span>
                          </a>
                        </li>
                      </ul>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Layout>
    )
  }
}

export default Registrazione
